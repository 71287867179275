import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';
import CancelIcon from '@mui/icons-material/Cancel';

import { AppColors, config } from '../../utils/utils';
import { useEffect, useState } from 'react';
import { setItem } from '../../utils/localStorage';
import { useAppSelector } from '../../store';
import { isMobile } from '../../utils/getMobileOperatingSystem';

type IProps = {
  toggle: boolean;
  onClose: () => void;
};

const opts = {
  height: '100%',
  width: '100%',
};

const DownloadAppModel: React.FC<IProps> = ({ toggle, onClose }) => {
  const { user } = useAppSelector((state) => state?.auth);

  const handleCloseTapped = () => {
    onClose();
  };

  const handleDontShowAgain = () => {
    setItem(config.DOWNLOAD_APP_DONT_SHOW_AGAIN_AFTER_SIGN_UP, user?.username);
    onClose();
  };

  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {
          onClose();
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        fullScreen={isMobile() ? true : false}
        PaperProps={{
          sx: !isMobile()
            ? {
                minHeight: '70vh',
                maxHeight: '70vh',
              }
            : null,
        }}
        sx={{
          zIndex: 3000,
        }}
      >
        <>
          <DialogContent
            sx={{
              height: isMobile() ? '100%' : '70vh',
              backgroundColor: 'rgba(32, 153, 211, 1)',
              backgroundImage:
                'url("/static/images/download_app_background.png")',
              backgroundSize: 'contain',
              backgroundRepeat: 'repeat',
              p: 0,
            }}
          >
            <>
              <IconButton
                aria-label='close'
                onClick={handleCloseTapped}
                sx={{
                  position: 'absolute',
                  right: 0,
                  zIndex: 100000,
                  color: AppColors.redColor,
                  alignSelf: 'center',
                  alignItems: 'center',
                }}
              >
                <CancelIcon />
              </IconButton>
              <Box p={5} mt={5}>
                <Typography
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    color: 'black',
                    pt: 1,
                    pb: 1.5,
                    // fontFamily: 'sans-serif',
                  }}
                  align='center'
                >
                  Let's Get You Sharing!
                </Typography>

                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white',
                    pt: 1,
                    pb: 1.5,
                    // fontFamily: 'sans-serif',
                  }}
                  align='center'
                >
                  Download From the App or Google Play Store & Sign in With Your
                  User Credentials...
                </Typography>

                <Box
                  display='flex'
                  flexDirection={'column'}
                  alignItems='center'
                  justifyContent='center'
                  mt={1.5}
                >
                  <a
                    href={config.PLAY_STORE_URL}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      textDecoration: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <img
                      src='/static/images/GetItOnGooglePlay.png'
                      height={window?.innerWidth > 500 ? '55%' : '70%'}
                      width={window?.innerWidth > 500 ? '55%' : '70%'}
                      alt='google-link'
                    />
                  </a>

                  <a
                    href={config.APP_STORE_URL}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      textDecoration: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <img
                      src='/static/images/app_store_black_icon.png'
                      height={window?.innerWidth > 500 ? '55%' : '70%'}
                      width={window?.innerWidth > 500 ? '55%' : '70%'}
                      alt='apple-link'
                    />
                  </a>
                </Box>

                <Box display={'flex'} justifyContent={'center'}>
                  <Button
                    variant='contained'
                    onClick={handleDontShowAgain}
                    endIcon={<CancelIcon sx={{ color: AppColors.redColor }} />}
                    sx={{
                      color: AppColors.whiteColor,
                      textTransform: 'unset',
                      mt: 2,
                      borderRadius: '12px',
                    }}
                  >
                    Don't show again
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  position: isMobile() ? 'fixed' : null,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: isMobile() ? '30%' : '38.5%', // Adjust height as needed
                  backgroundColor: '#ffffff',
                  borderTopLeftRadius: '30px',
                  borderTopRightRadius: '30px',
                  display: isMobile() ? 'flex' : null,
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  zIndex: 1000,
                  pt: isMobile() ? 0 : 3,
                }}
              >
                <Box
                  component='img'
                  src='/static/images/app_download_footer_image.png' // Replace with your image URL
                  alt='Background'
                  sx={{
                    position: 'absolute',
                    left: '15%',
                    width: 'auto',
                    height: isMobile() ? '70%' : '30%',
                  }}
                />
                <Box
                  component='img'
                  src='/static/images/mobile_device.png' // Replace with your image URL
                  alt='Background'
                  sx={{
                    position: 'absolute',
                    right: '10%',
                    width: 'auto',
                    height: isMobile() ? '70%' : '30%',
                  }}
                />
              </Box>
            </>
          </DialogContent>
        </>
      </Dialog>
    </>
  );
};

export default DownloadAppModel;
