import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import '@splidejs/react-splide/css';

import { PaypalInitialOptions, config } from '../../utils/utils';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PaypalOrderButton from '../Paypal/PaypalOrderButton';
import PaypalSubscriptionButton from '../Paypal/PaypalSubscriptionButton';
import { createStripeOrder, pubscriptionPurchaseSuccess } from '../../store/purchase/api';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import { useNavigate } from 'react-router-dom';

import { PaymentGateway } from '../../enum';
import { useEffect } from 'react';
import { getPlanLabel, showErrorMessage, showSuccessMessage } from '../../utils/utilMethods';
import { getItem, removeItem } from '../../utils/localStorage';

type IProps = {
  toggle: boolean;
  onClose: () => void;
  purchaseType: string;
  isAnnuallySubscription: boolean;
};

const opts = {
  height: '100%',
  width: '100%',
};

const PurchaseModel: React.FC<IProps> = ({ toggle, onClose, purchaseType, isAnnuallySubscription }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);

  var title = getPlanLabel(purchaseType);

  const isKnoCardLinkSelected = getItem(config.IS_KNOCARD_LINK_SELECTED_KEY)
  const isKnoCardSpotlightSelected = getItem(config.IS_KNOCARD_SPOTLIGHT_SELECTED_KEY)
  const isKnoCardLeadersCornerSelected = getItem(config.IS_KNOCARD_LEADERS_CORNER_SELECTED_KEY)

  function onProOnePurchaseSucess(data: any, action: any) {
    return updatePurchaseDetail(data, action, config.PRO_1);
  }

  function onProPlusPurchaseSucess(data: any, action: any) {
    return updatePurchaseDetail(data, action, config.PRO_PLUS);
  }

  function onProMontlyPurchaseSucess(data: any, action: any) {
    return updatePurchaseDetail(data, action, config.PRO_BASIC);
  }

  function onProYearlyPurchaseSucess(data: any, action: any) {
    return updatePurchaseDetail(data, action, config.PRO_PRO);
  }

  const initiatPayment = async () => {
    try {
      var result: any = await dispatch(createStripeOrder({
        api_token: accessToken ?? '', code: purchaseType, isAnnuallySubscription: isAnnuallySubscription, redirect_url: `${config.VBC_URL}/dashboard`,
        is_knocard_link: ((isKnoCardLinkSelected && purchaseType == config.PRO_PRO && isAnnuallySubscription == false)),
        is_knocard_leaders_corner: isKnoCardLeadersCornerSelected,
        is_knocard_spotlight: isKnoCardSpotlightSelected,
      })).unwrap();
      if (result.status == 200 && result.data.payment_url != undefined) {
        removeItem(config.REQUIRED_SUBSCRIPTION_KEY);
        window.open(`${result.data.payment_url}`, "_self") || window.location.replace(`${result.data.payment_url}`);
      } else {
        if (result.data.status.description != undefined) {
          showErrorMessage(result.data.status.description);
        }
        onClose();
      }
    } catch (e) {
      onClose();
    }
  }

  useEffect(() => {
    if (toggle == true) {
      initiatPayment();
    }
  }, [toggle]);

  async function updatePurchaseDetail(data: any, action: any, purchasePlanType: any) {

    let result: any = undefined;

    if (purchasePlanType == config.PRO_1) {
      result = await dispatch(
        pubscriptionPurchaseSuccess({
          api_token: accessToken ?? '',
          code: purchasePlanType,
          purchase_code: data.paymentID,
          server_verification_data: data.orderID,
          source: data.paymentSource
        }));

    } else {
      result = await dispatch(
        pubscriptionPurchaseSuccess({
          api_token: accessToken ?? '',
          code: purchasePlanType,
          purchase_code: data.orderID,
          server_verification_data: data.subscriptionID,
          source: data.paymentSource
        }));
    }

    if (result?.payload?.status == 200) {
      showSuccessMessage(`${title} plan purchased successfully`);
      navigate('/dashboard/user-profile');
    }

    return new Promise<void>((resolve, reject) => { resolve(); });
  }


  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {

        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#088ac6',
            color: 'white',
            pt: '6px',
            pb: '6px'
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            zIndex: 100000,
            color: 'white',
            alignSelf: 'center',
            alignItems: 'center',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: 0 }}>

          <Box
            margin={5}
            alignItems='center'
            justifyContent='center'
            mb={window?.innerWidth > 500 ? 2 : 1}
          >
            {config.PAYMENT_GATEWAY == PaymentGateway.paypal && (<PayPalScriptProvider options={PaypalInitialOptions}>
              {purchaseType == config.PRO_1 && (<PaypalOrderButton onApprove={onProOnePurchaseSucess} />)}
              {purchaseType == config.PRO_PRO && (<PaypalSubscriptionButton planId={config.PAYPAL_PRO_YEARLY_PLAN_ID} onApprove={onProYearlyPurchaseSucess} />)}
              {purchaseType == config.PRO_BASIC && (<PaypalSubscriptionButton planId={config.PAYPAL_PRO_MONTHLY_PLAN_ID} onApprove={onProMontlyPurchaseSucess} />)}
            </PayPalScriptProvider>)}

            {config.PAYMENT_GATEWAY == PaymentGateway.stripe && (<><Stack direction={'column'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}> <CircularProgress /></Stack></>)}

          </Box>

        </DialogContent>
      </Dialog>
    </>
  );
};

export default PurchaseModel;
